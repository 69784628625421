
<template>
  <el-dialog
    width="700px"
    class="receipt-form-popup"
    title="Thông tin đơn hàng"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-tabs v-model="activeTab">
      <el-tab-pane name="info" label="Thông tin chung">
        <el-form
          ref="form"
          class="mt-4"
          :model="data"
          :rules="rules"
          label-position="top"
        >
          <el-form-item label="Mã đơn hàng" prop="code">
            <el-input v-model="data.code" disabled></el-input>
          </el-form-item>
          <el-form-item label="Tổng tiền đơn hàng">
            <el-input
              v-model.number="data.total"
              type="number"
              :disabled="callingAPI"
            ></el-input>
          </el-form-item>
          <el-form-item label="Trạng thái">
            <el-select v-model="data.status" placeholder="Chọn trạng thái">
              <el-option
                v-for="item in status"
                :key="item.index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane name="customer" label="Thông tin khách hàng">
        <el-form
          ref="form"
          class="mt-4"
          :model="data"
          :rules="rules"
          label-position="top"
        >
          <el-form-item label="Email" prop="email">
            <el-input
              v-model="data.email"
              :disabled="callingAPI"
              placeholder="Nhập email"
            ></el-input>
          </el-form-item>
          <el-row :gutter="12">
            <el-col :lg="12">
              <el-form-item label="Tên" prop="customer_name">
                <el-input
                  v-model="data.customer_name"
                  :disabled="callingAPI"
                  placeholder="Nhập tên"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12">
              <el-form-item label="Số điện thoại" prop="phone">
                <el-input
                  v-model="data.phone"
                  :disabled="callingAPI"
                  placeholder="Nhập số điện thoại"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Địa chỉ" prop="address">
            <el-input
              v-model="data.address"
              :disabled="callingAPI"
              placeholder="Nhập địa chỉ"
            ></el-input>
          </el-form-item>
          <el-form-item label="Thành viên trong nhóm" prop="description">
            <el-input
              v-model="data.description"
              :disabled="callingAPI"
              rows="5"
              type="textarea"
              placeholder="Nhập thông tin tên, ngày sinh của các thành viên"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Đóng</el-button>
      <el-button
        type="primary"
        :loading="callingAPI"
        @click="handleSaveInfo"
      >Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'ReceiptForm',
  props: {
    visible: Boolean,
    data: Object
  },
  data() {
    return {
      callingAPI: false,
      activeTab: 'info',
      status: [
        { label: 'Chưa thanh toán', value: 'open' },
        { label: 'Đã thanh toán', value: 'paid' }
      ],
      rules: {
        email: [
          { required: true, message: 'Địa chỉ email không được để trống', trigger: ['blur', 'change'] },
          { type: 'email', message: 'Địa chỉ email không hợp lệ', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    handleSaveInfo() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.callingAPI = true
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.receipt-form-popup {
  .el-select {
    width: 100%;
  }
  .el-tabs__item {
    background-color: initial !important;
  }
}
</style>
